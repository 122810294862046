import type { Me, MeToken, Profile } from '@/types/me'
import { Broker, ProfileType } from '@/types/me'
import { RequestStatus, handleRequest, initialRequestState } from '@/utils/restStore'
import type { RequestState } from '@/utils/restStore'
import { defineStore } from 'pinia'

import Api from '@/services/api'

interface BookameetingParams {
  firstName: string
  lastName: string
  email: string
  phone: string
}
interface State {
  me: RequestState<Me>
  meToken: RequestState<MeToken>
  bookameetingParams: BookameetingParams
  profiles: RequestState<Profile[]>
  selectedCompany: string | null
  selectedClient: string | null
}

const initialState = (): State => ({
  me: initialRequestState(),
  meToken: initialRequestState(),
  bookameetingParams: {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  },
  profiles: initialRequestState(),
  selectedCompany: localStorage.getItem('selectedCompanyProfile') || null,
  selectedClient: localStorage.getItem('selectedClientProfile') || null
})

export const useMeStore = defineStore({
  id: 'me',
  state: initialState,
  actions: {
    async fetchMe() {
      await handleRequest(Api.getMe, (me: RequestState<Me>) => {
        if (me.status === RequestStatus.RequestLoaded) {
          this.bookameetingParams.firstName = me.data.firstName
          this.bookameetingParams.lastName = me.data.lastName
          this.bookameetingParams.email = me.data.email
          this.bookameetingParams.phone = me.data.mobilePhone ? me.data.mobilePhone.replace('+', '00') : ''
          localStorage.setItem('user_locale', me.data.locale)
        }
        this.me = me
      })
    },
    async fetchToken() {
      await handleRequest(Api.getMeToken, (token: RequestState<MeToken>) => {
        this.meToken = token
        if (token.status === RequestStatus.RequestLoaded) {
          localStorage.setItem('authToken', token.data.token)
        }
      })
    },
    async fetchProfiles() {
      await handleRequest(Api.getUserProfiles, (profiles: RequestState<Profile[]>) => {
        this.profiles = profiles
        if (profiles.status === RequestStatus.RequestLoaded) {
          if (this.selectedClient && !profiles.data.find((profile) => profile.clientId === this.selectedClient)) {
            //* reset selected client profile when logged to new user
            localStorage.removeItem('selectedClientProfile')
            this.selectedClient = null
          }
          if (
            this.selectedCompany &&
            !profiles.data.find((profile) => profile.company?.companyId === this.selectedCompany)
          ) {
            //* reset selected company profile when logged to new user
            localStorage.removeItem('selectedCompanyProfile')
            this.selectedCompany = null
          }
          if (profiles.data.length === 1) {
            localStorage.setItem('selectedClientProfile', profiles.data[0].clientId as string)
            this.selectedClient = profiles.data[0].clientId as string
          }
        }
      })
    },
    changeSelectedProfile(profile: Profile) {
      if (profile.profileType === ProfileType.B2B) {
        //* set selected company profile
        localStorage.setItem('selectedCompanyProfile', profile.company?.companyId as string)
        this.selectedCompany = profile.company?.companyId as string
        //* reset selected client profile
        localStorage.removeItem('selectedClientProfile')
        this.selectedClient = null
      } else if (profile.profileType === ProfileType.B2C) {
        //* set selected client profile
        localStorage.setItem('selectedClientProfile', profile.clientId as string)
        this.selectedClient = profile.clientId as string
        //* reset selected company profile
        localStorage.removeItem('selectedCompanyProfile')
        this.selectedCompany = null
      }
    },
    async postOnboarded() {
      await handleRequest(Api.postOnboard, () => {})
    }
  },
  getters: {
    marshClient: (state: State): boolean => {
      return state.me.status === RequestStatus.RequestLoaded ? state.me.data.fromBrokerId === Broker.Marsh : false
    },
    account: (state: State): Me | null => {
      return state.me.status === RequestStatus.RequestLoaded ? state.me.data : null
    },
    isUnfit(state: State): boolean {
      return state.me.status === RequestStatus.RequestLoaded && state.me.data.unfit
    },
    showOnboarding(state: State): boolean {
      return state.me.status === RequestStatus.RequestLoaded && !state.me.data.onboarded
    }
  }
})
