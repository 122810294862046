<script lang="ts" setup>
import { useMeStore } from '@/stores/me'
import type { Profile } from '@/types/me'
import { currentProfileName } from '@/utils/profile'
import { RequestStatus } from '@/utils/restStore'
import { ref, watch } from 'vue'

import UIIcon from '@/components/generic/atoms/UIIcon.vue'

const meStore = useMeStore()

const selectedId = ref(meStore.selectedClient || meStore.selectedCompany)

watch(
  () => meStore.selectedClient,
  async (value) => {
    if (value) {
      selectedId.value = value
    }
  }
)

watch(
  () => meStore.selectedCompany,
  async (value) => {
    if (value) {
      selectedId.value = value
    }
  }
)

const selectProfile = (event: Event, profiles: Profile[]) => {
  const id = (event.target as HTMLInputElement)?.value
  let profile = null
  if (profiles.find((profile) => profile.clientId === id)) {
    // assign client profil
    profile = profiles.find((profile) => profile.clientId === id)
  } else if (profiles.find((profile) => profile.company?.companyId === id)) {
    // assign company profil
    profile = profiles.find((profile) => profile.company?.companyId === id)
  }
  meStore.changeSelectedProfile(profile as Profile)
}
</script>

<template>
  <div
    v-if="meStore.profiles.status === RequestStatus.RequestLoaded && meStore.profiles.data.length > 1"
    class="lg:mx-8 lg:my-2 lg:mb-10 relative transform -translate-y-4 lg:transform-none w-full md:w-fit lg:w-auto">
    <select
      v-model="selectedId"
      @change="selectProfile($event, meStore.profiles.data)"
      class="lg:border lg:border-white lg:border-opacity-20 w-full md:w-auto rounded-md pl-3 py-3 pr-8 text-sm bg-sage-200 lg:bg-transparent lg:text-white">
      <option
        :value="profile.clientId || profile.company?.companyId"
        v-for="profile in meStore.profiles.data"
        :key="profile.company?.companyId || profile.clientId">
        {{ currentProfileName(profile) }}
      </option>
    </select>
    <UIIcon route-name="chevron-down" class="absolute top-3 right-2 lg:text-white" />
  </div>
</template>

<style scoped>
select {
  -webkit-appearance: none;
}

@media screen and (min-width: 1024px) {
  select {
    width: -webkit-fill-available;
  }
}
</style>
